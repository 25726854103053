import invalidateCacheByTypename from '@core/graphql/utils/invalidateCacheByTypename';

import getReelsSliderObserver from '@phoenix/reels/constants/getReelsSliderObserver';
import {setCanFetchMore} from '@phoenix/reels/constants/canFetchMore';

const carouselObserver$ = getReelsSliderObserver();

const clearReelsCache = (client, broadcast) => {
  invalidateCacheByTypename(client, 'UserFeatures', {
    fieldName: 'reelsFeature',
    broadcast,
  });

  // reset reels slider current index
  carouselObserver$.next(0);

  // reset reels fetch more flag
  setCanFetchMore(true);
};

export default clearReelsCache;
