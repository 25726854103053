import find from 'lodash/find';

import useActivePackage from '@core/payment/widgets/package/utils/useActivePackage';
import usePaymentAdditionalPackage from '@core/payment/widgets/additionalPackage/utils/usePaymentAdditionalPackage';
import {ADDITIONAL_PACKAGE_OTHER_TYPE} from '@core/payment/widgets/footer/constants/additionalTypes';

const useActivationFeeText = (): string | null => {
  const {activePackage} = useActivePackage();
  const {additionalPackages} = usePaymentAdditionalPackage();

  if (!activePackage?.additionalPackageList?.length) {
    return null;
  }

  const activationFeePackage = find(activePackage.additionalPackageList, {
    withCheckbox: false,
    __typename: ADDITIONAL_PACKAGE_OTHER_TYPE,
  });

  if (!additionalPackages?.length || !activationFeePackage) {
    return null;
  }

  const activationFeeFooterData = find(additionalPackages, {
    packageId: activationFeePackage.packageId,
  });

  if (!activationFeeFooterData?.translateMessage) {
    return null;
  }

  const {
    formatted: {amount},
    currency: {prefix, suffix},
  } = activationFeePackage.repeatPrice;

  return activationFeeFooterData.translateMessage
    .replace('{amount}', amount)
    .replace('{currency}', prefix || suffix);
};

export default useActivationFeeText;
