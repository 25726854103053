import type {FC} from 'react';
import React from 'react';

import usePaymentDNSMPI from '@core/payment/widgets/dnsmpi/utils/usePaymentDNSMPI';

import FooterDNSMPI from '@phoenix/footer/components/FooterDNSMPI';
import {SpacingSize} from '@phoenix/ui/constants';
import type {LinkProps} from '@phoenix/typography';

import FooterRow from '../../footer/components/FooterRow';

type PaymentDNSMPIProps = LinkProps & {
  space?: SpacingSize;
};

const PaymentDNSMPI: FC<PaymentDNSMPIProps> = ({
  space = SpacingSize.SHORT,
  ...props
}) => {
  const {dnsmpiLink, error, loading} = usePaymentDNSMPI();

  if (loading || error || !dnsmpiLink) {
    return null;
  }

  return (
    <FooterRow space={space}>
      <FooterDNSMPI text={dnsmpiLink} {...props} />
    </FooterRow>
  );
};

export default PaymentDNSMPI;
