import {useEffect} from 'react';
import type {ApolloError} from '@apollo/client';

import usePaymentQuery from '@core/payment/common/utils/usePaymentQuery';
import isInstantRepeatCheckedVar from '@core/graphql/vars/isInstantRepeatCheckedVar';

import COINS_AUTOFILL_AGREEMENT_QUERY from '../graphql/queries/coinsAutofillAgreement.gql';
import type {
  CoinsAutofillAgreementQuery,
  CoinsAutofillAgreementQueryVariables,
} from '../graphql/queries/coinsAutofillAgreement';

type UseConsAutofillAgreementResult = {
  loading: boolean;
  error?: ApolloError;
  available: boolean;
  text: string;
};

const useCoinsAutofillAgreement = (): UseConsAutofillAgreementResult => {
  const {data, loading, error} = usePaymentQuery<
    CoinsAutofillAgreementQuery,
    CoinsAutofillAgreementQueryVariables
  >(COINS_AUTOFILL_AGREEMENT_QUERY);

  const available =
    data?.payment.templateSettings.additionalTerms.creditAutofillAgreement ||
    false;

  const checkedByDefault =
    data?.payment.templateSettings.additionalTermsStatusMap
      .creditAutofillAgreement || false;

  const text =
    data?.payment.paymentFooter.blockDataMap.textCoinsAutofillAgreement || '';

  useEffect(() => {
    if (available && isInstantRepeatCheckedVar() === null) {
      isInstantRepeatCheckedVar(checkedByDefault);
    }
  }, [available, checkedByDefault]);

  return {
    loading,
    error,
    available,
    text,
  };
};

export default useCoinsAutofillAgreement;
