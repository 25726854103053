import type {FC} from 'react';
import React from 'react';

import {PlaceholderInput, SpacingLarge} from '@phoenix/ui';
import {SpacingDirection} from '@phoenix/ui/constants';

const PaymentFooterPlaceholder: FC = () => (
  <SpacingLarge direction={SpacingDirection.HORIZONTAL}>
    <PlaceholderInput />
  </SpacingLarge>
);

export default PaymentFooterPlaceholder;
