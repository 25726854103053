import React from 'react';
import {useLocation} from 'react-router-dom';
import find from 'lodash/find';

import useActivePackage from '@core/payment/widgets/package/utils/useActivePackage';
import usePaymentQuery from '@core/payment/common/utils/usePaymentQuery';
import usePaymentParams from '@core/payment/common/utils/usePaymentParams';
import PAYMENT_ACTIONS from '@core/payment/common/constants/paymentActions';
import addActivePackageIdInUrl from '@core/payment/widgets/package/utils/addActivePackageIdInUrl';
import PAYMENT_SECURITY_LOGOS_QUERY from '@core/payment/widgets/securityLogos/graphql/queries/paymentSecurityLogos.gql';
import isPayUrl from '@core/utils/url/isPayUrl';

import {PlaceholderBar} from '@phoenix/ui';
import ErrorBoundary from '@phoenix/graphql/components/ErrorBoundary';
import useIsClickableElements from '@phoenix/payment/common/utils/useIsClickableElements';

import PaymentSecurityLogosLayout from '../components/PaymentSecurityLogosLayout';
import FooterRow from '../../footer/components/FooterRow';

const handleLogoClick = () => addActivePackageIdInUrl();

/**
 * @desc Security logos for payment page
 */
const PaymentSecurityLogos = () => {
  const {action} = usePaymentParams();
  const {activePackage} = useActivePackage();
  const location = useLocation();

  const {data, error, loading} = usePaymentQuery(PAYMENT_SECURITY_LOGOS_QUERY);

  const {loading: isClickableElementsLoading, isClickableElements} =
    useIsClickableElements();
  const isClickableLogo =
    isClickableElements &&
    isPayUrl(location.pathname) &&
    PAYMENT_ACTIONS.REMARKETING_OFFER !== action;

  if (loading || isClickableElementsLoading) {
    return (
      <FooterRow>
        <PlaceholderBar />
      </FooterRow>
    );
  }

  if (error) {
    return (
      <FooterRow>
        <ErrorBoundary error={error} />
      </FooterRow>
    );
  }

  if (!activePackage || !data) return null;

  const {
    payment: {
      packagesData: {paymentMethodLogos},
    },
  } = data;

  const securityLogos = find(paymentMethodLogos, {
    method: activePackage.paymentMethod,
  })?.securityLogos;

  if (!securityLogos) {
    return null;
  }

  return (
    <FooterRow>
      <PaymentSecurityLogosLayout
        securityLogos={securityLogos}
        // eslint-disable-next-line local-rules/tracking-name -- has tracking inside
        onClick={isClickableLogo ? handleLogoClick : null}
      />
    </FooterRow>
  );
};

export default PaymentSecurityLogos;
