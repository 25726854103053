import type {FC} from 'react';
import React, {useMemo, Fragment, memo} from 'react';

import {decodeCopyright} from '@core/utils/processText';
import type {CopyrightUnifyData, DisclaimerFrontendData} from '@core/types';

import {TextMuted} from '@phoenix/typography';

import Disclaimer from '../../../../footer/components/Disclaimer';

type PaymentCopyrightProps = {
  copyrightUnifyData?: CopyrightUnifyData;
  disclaimerFrontendData?: DisclaimerFrontendData;
  copyrightCompanyName: string;
};

const PaymentCopyright: FC<PaymentCopyrightProps> = ({
  copyrightUnifyData,
  disclaimerFrontendData,
  copyrightCompanyName,
}) => {
  const text = useMemo(() => {
    const unifyCopyrightText =
      copyrightUnifyData && copyrightUnifyData.unifyCopyrightText;

    if (unifyCopyrightText) {
      return copyrightUnifyData.isPlainText
        ? unifyCopyrightText
        : decodeCopyright(unifyCopyrightText);
    }

    if (copyrightCompanyName) {
      return `© Copyright ${copyrightCompanyName}`;
    }

    return '';
  }, [copyrightUnifyData, copyrightCompanyName]);

  return (
    <Fragment>
      {Boolean(disclaimerFrontendData && disclaimerFrontendData.message) && (
        <Disclaimer
          href={disclaimerFrontendData.disclaimerUrl}
          text={disclaimerFrontendData.message}
          muted
          underline
          data-test="paymentCopyrightDisclaimer"
        />
      )}
      {Boolean(text) && (
        <TextMuted
          small
          align={TextMuted.ALIGN.CENTER}
          data-test="paymentCopyrightText"
        >
          {text}
        </TextMuted>
      )}
    </Fragment>
  );
};

export default memo(PaymentCopyright);
