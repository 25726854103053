import {getClientInstance} from '@core/graphql/client';
import invalidateCacheByTypename from '@core/graphql/utils/invalidateCacheByTypename';

/**
 * Update microfeatures related data in cache.
 */
const updateMicroFeaturesData = ({broadcast = true} = {}) => {
  const client = getClientInstance();

  invalidateCacheByTypename(client, 'MicroFeatures', {
    fieldName: 'microFeatureList',
    broadcast,
  });
  invalidateCacheByTypename(client, 'MicroFeatures', {
    fieldName: 'creditFeatureList',
    broadcast,
  });
};

export default updateMicroFeaturesData;
