import type {FC} from 'react';
import React from 'react';

import PaymentFooterCommon from '@core/payment/widgets/footer/containers/PaymentFooterCommon';
import type {PaymentFooterProps} from '@core/payment/widgets/footer/types/paymentFooterProps';

import ErrorBoundary from '@phoenix/graphql/components/ErrorBoundary';

import PaymentFooterLayout from '../components/PaymentFooterLayout';
import PaymentFooterPlaceholder from '../components/PaymentFooterPlaceholder';

const PaymentFooter: FC<PaymentFooterProps> = (props) => (
  <PaymentFooterCommon
    layout={PaymentFooterLayout}
    placeholder={PaymentFooterPlaceholder}
    errorBoundary={ErrorBoundary}
    {...props}
  />
);

export default PaymentFooter;
