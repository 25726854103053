import type {FC, ReactNode, Ref} from 'react';
import React, {Fragment} from 'react';

import Size from '@core/search/widget/constants/size';

import {Card} from '@phoenix/ui';

import SearchWidgetSpacing from './SearchWidgetSpacing';
import css from '../styles/SearchWidgetLayout.css';

export type SearchWidgetLayoutProps = {
  size?: Size;
  /**
   * Wrap or not widget inside additional spacing widget.
   * If set to 'false' - 'size' prop have no effect.
   */
  spaced?: boolean;
  children: ReactNode;
  actions?: ReactNode;
  withoutBottomIndent?: boolean;
  /**
   * Props for setting min-height on Card that wrapping content
   * min-height sets only for SearchMotivationEditableWidgets after user made actions on content
   * @see SearchMotivationEditableInfoWidget
   */
  minHeight?: number;
  innerRef?: Ref<HTMLDivElement>;
  highlighted?: boolean;
  onPageBackground?: boolean;
  wrappedInCard?: boolean;
  light?: boolean;
};

/**
 * Universal component for search widget (user and other)
 */
const SearchWidgetLayout: FC<SearchWidgetLayoutProps> & {SIZE: typeof Size} = ({
  size = Size.FLUID,
  spaced = true,
  children,
  actions,
  withoutBottomIndent,
  minHeight,
  innerRef,
  highlighted = false,
  onPageBackground = true,
  wrappedInCard = true,
  light = false,
  ...props
}) => {
  const content = (
    <Fragment>
      <div className={css.holder}>{children}</div>
      {actions && <div className={css.actions}>{actions}</div>}
    </Fragment>
  );

  const wrappedContent = wrappedInCard ? (
    <Card
      onPageBackground={onPageBackground}
      light={light}
      className={css.wrapper}
      innerRef={innerRef}
      highlighted={highlighted}
      style={minHeight ? {minHeight} : {}}
    >
      {content}
    </Card>
  ) : (
    <div className={css.wrapper} ref={innerRef}>
      {content}
    </div>
  );

  if (spaced) {
    return (
      <SearchWidgetSpacing
        size={size}
        data-test="searchWidget"
        withoutBottom={withoutBottomIndent}
        {...props}
      >
        {wrappedContent}
      </SearchWidgetSpacing>
    );
  }

  return wrappedContent;
};

SearchWidgetLayout.SIZE = Size;

export default SearchWidgetLayout;
