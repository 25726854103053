import {getClientInstance} from '@core/graphql/client';
import invalidateCacheByTypename from '@core/graphql/utils/invalidateCacheByTypename';
import type {ProfileVideoUploadDisabledQuery} from '@core/mediaUpload/graphql/queries/profileVideoUploadDisabled';
import PROFILE_VIDEO_UPLOAD_DISABLED_QUERY from '@core/mediaUpload/graphql/queries/profileVideoUploadDisabled.gql';

const invalidateCacheIsProfileVideoUploadDisabled = (
  client = getClientInstance(),
  broadcast = true,
): void => {
  const data = getClientInstance().readQuery<ProfileVideoUploadDisabledQuery>({
    query: PROFILE_VIDEO_UPLOAD_DISABLED_QUERY,
  });

  if (!data?.userFeatures.isProfileVideoUploadAllow) {
    invalidateCacheByTypename(client, 'UserFeatures', {
      fieldName: 'isProfileVideoUploadAllow',
      broadcast,
    });
  }
};

export default invalidateCacheIsProfileVideoUploadDisabled;
