import type {FC} from 'react';
import React from 'react';

import t from '@core/translations/translate';
import type {TranslateMessageEntityMap} from '@core/types';

import {TextMuted} from '@phoenix/typography';

import FooterRow from './FooterRow';

// export for tests
export type PaymentUserPublicIdProps = {
  inverse?: boolean;
  shortInfo?: boolean;
  publicUserId?: string;
  translateMessageEntityMap?: TranslateMessageEntityMap;
  small?: boolean;
};

const PaymentUserPublicId: FC<PaymentUserPublicIdProps> = ({
  inverse = false,
  shortInfo = false,
  publicUserId,
  translateMessageEntityMap,
  small,
}) => {
  if (shortInfo) {
    return (
      <FooterRow>
        <TextMuted inverse={inverse} small={small}>
          {t('paymentPageSidebar', 'text.tell_your_userId', {
            '{userId}': publicUserId,
          })}
        </TextMuted>
      </FooterRow>
    );
  }
  const {assistanceText, publicUserIdText} = translateMessageEntityMap;

  if (!assistanceText && !publicUserIdText) {
    return null;
  }

  return (
    <FooterRow>
      {assistanceText}
      {publicUserIdText && (
        <div
          dangerouslySetInnerHTML={{
            __html: publicUserIdText.replace('{publicId}', publicUserId),
          }}
        />
      )}
    </FooterRow>
  );
};

export default PaymentUserPublicId;
