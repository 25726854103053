import type {FC} from 'react';
import React, {Fragment} from 'react';

import type {PaymentFooterLayoutProps} from '@core/payment/widgets/footer/types/paymentFooterProps';
import useIsGooglePayCvvForm from '@core/payment/forms/googlePay/utils/useIsGooglePayCvvForm';
import usePaymentPackageDescriptionRule from '@core/payment/widgets/packageDescription/utils/usePaymentPackageDescriptionRule';

import {Text, TextMuted} from '@phoenix/typography';
import {Row, RowShort} from '@phoenix/ui';
import {Align, SpacingSize} from '@phoenix/ui/constants';
import ContactsForCommunication from '@phoenix/support/common/containers/ContactsForCommunication';

import PaymentPackageDescription from '../../packageDescription/containers/PaymentPackageDescription';
import PaymentAdditionalPackage from '../../additionalPackage/containers/PaymentAdditionalPackage';
import PaymentBillingPolicy from '../../billingPolicy/containers/PaymentBillingPolicy';
import PaymentCredentialsPolicyFactory from '../../billingPolicy/components/PaymentCredentialsPolicyFactory';
import PaymentSecurityLogos from '../../securityLogos/containers/PaymentSecurityLogos';
import PaymentDescriptor from '../../descriptor/containers/PaymentDescriptor';
import PaymentDNSMPI from '../../dnsmpi/containers/PaymentDNSMPI';
import PaymentDetails from '../../details/components/PaymentDetails';
import PaymentAdditionalDetails from '../../details/containers/PaymentAdditionalDetails';
import PaymentCopyright from '../containers/PaymentCopyright';
import PaymentFooterGuaranteesAndReturnsStatement from '../containers/PaymentFooterGuaranteesAndReturnsStatement';
import PaymentFooterCoinsAutofillAgreement from '../containers/PaymentFooterCoinsAutofillAgreement';
import useActivationFeeText from '../utils/useActivationFeeText';
import FooterGroup from './FooterGroup';
import FooterRow from './FooterRow';
import PaymentHotLine from './PaymentHotLine';
import PaymentUserPublicId from './PaymentUserPublicId';
import PaymentLicense from './PaymentLicense';
import PaymentKonbiniInfo from '../containers/PaymentKonbiniInfo';
import css from '../styles/PaymentFooterLayout.css';

const PaymentFooterLayout: FC<PaymentFooterLayoutProps> = ({
  isAdditionalPackageFirst,
  packageDescriptionOnTop,
  subscriptionInfo,
  withAdditionalPackage,
  withActivationFee,
  withBillingPolicy: withBillingPolicyFromProps,
  withCredentialsPolicy,
  withCopyright,
  withDescriptor,
  withDetails,
  withContacts,
  withAdditionalDetails,
  isAutoRenewalAgree,
  withDnsmpi,
  withGuarantees,
  withKonbiniInfo,
  isSliderFooter,
  withPackageDescription: withPackageDescriptionFromProps,
  billingPolicyRenderer,
  packageDescription: packageDescriptionFromProps,
  withSecurityLogos,
  withHotLine,
  withUserPublicId,
  withCurrencyConvertStatement,
  withCoinsAutofillAgreement,
  paymentButtonTitle,
  onlyProcessingFeeText,
  copyright,
  currencyConvertStatement,
  hotlineCreditCard,
  userPublicId,
  footerLicense,
}) => {
  const isGooglePayCvvForm = useIsGooglePayCvvForm();
  const paymentActivationFee = useActivationFeeText();

  const {isWithBillingPolicy: isPackageDescriptionWithBillingPolicy} =
    usePaymentPackageDescriptionRule();

  const withBillingPolicy =
    withBillingPolicyFromProps &&
    !isPackageDescriptionWithBillingPolicy &&
    !isGooglePayCvvForm;

  const withPackageDescription = isPackageDescriptionWithBillingPolicy
    ? withBillingPolicyFromProps
    : withPackageDescriptionFromProps;

  const packageDescription = packageDescriptionFromProps ? (
    <FooterRow>{packageDescriptionFromProps}</FooterRow>
  ) : (
    <PaymentPackageDescription
      withCheckbox={isAutoRenewalAgree}
      paymentButtonTitle={paymentButtonTitle}
    />
  );

  return (
    <TextMuted small align={TextMuted.ALIGN.CENTER} className={css.wrap}>
      <FooterGroup>
        {withDetails && (
          <PaymentDetails onlyProcessingFeeText={onlyProcessingFeeText} />
        )}
        {withAdditionalDetails && <PaymentAdditionalDetails />}
        {withPackageDescription &&
          packageDescriptionOnTop &&
          packageDescription}
        {billingPolicyRenderer &&
          billingPolicyRenderer(copyright?.copyrightUnifyData)}
        {withSecurityLogos && <PaymentSecurityLogos />}
        {withKonbiniInfo && <PaymentKonbiniInfo />}
        {withGuarantees && <PaymentFooterGuaranteesAndReturnsStatement />}
        {withPackageDescription &&
          !packageDescriptionOnTop &&
          packageDescription}
        {withDescriptor && (
          <PaymentDescriptor align={Text.ALIGN.CENTER} muted small />
        )}
        {withCurrencyConvertStatement && currencyConvertStatement && (
          <FooterRow>{currencyConvertStatement}</FooterRow>
        )}
        {isAdditionalPackageFirst && withAdditionalPackage && (
          <PaymentAdditionalPackage paymentButtonTitle={paymentButtonTitle} />
        )}
        {withBillingPolicy && (
          <PaymentBillingPolicy paymentButtonTitle={paymentButtonTitle} />
        )}
        {withContacts && <ContactsForCommunication align={Align.CENTER} />}
        {withCredentialsPolicy && <PaymentCredentialsPolicyFactory />}
        {subscriptionInfo && <FooterRow>{subscriptionInfo}</FooterRow>}
        {!isAdditionalPackageFirst && withAdditionalPackage && (
          <PaymentAdditionalPackage paymentButtonTitle={paymentButtonTitle} />
        )}
        {withActivationFee && paymentActivationFee && (
          <FooterRow>{paymentActivationFee}</FooterRow>
        )}
        {withHotLine && hotlineCreditCard && (
          <PaymentHotLine {...hotlineCreditCard} />
        )}
        {withUserPublicId && userPublicId && (
          <PaymentUserPublicId {...userPublicId} />
        )}
        {withCoinsAutofillAgreement && <PaymentFooterCoinsAutofillAgreement />}
      </FooterGroup>
      <Row
        flexibleWidth={false}
        space={isSliderFooter ? SpacingSize.NONE : SpacingSize.NORMAL}
      >
        {withDnsmpi && <PaymentDNSMPI muted underline />}
        {withCopyright && (
          <Fragment>
            <RowShort>
              <PaymentCopyright
                copyrightUnifyData={copyright?.copyrightUnifyData}
                disclaimerFrontendData={copyright?.disclaimerFrontendData}
                copyrightCompanyName={copyright?.copyrightCompanyName}
              />
            </RowShort>
            {Boolean(footerLicense?.formattedList?.length) && (
              <Row>
                <PaymentLicense list={footerLicense.formattedList} />
              </Row>
            )}
          </Fragment>
        )}
      </Row>
    </TextMuted>
  );
};

export default PaymentFooterLayout;
