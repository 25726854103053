import type {FC} from 'react';
import React, {memo} from 'react';

import type {FooterLicense} from '@core/types';

type PaymentLicenseProps = {
  list: FooterLicense['formattedList'];
};

const PaymentLicense: FC<PaymentLicenseProps> = ({list}) => {
  if (!list?.length) {
    return null;
  }

  return list.map((item) => (
    <div key={item} dangerouslySetInnerHTML={{__html: item}} />
  ));
};

export default memo(PaymentLicense);
