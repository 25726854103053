import {useState, useContext, useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import {useQuery} from '@apollo/client';
import {useLocation} from 'react-router';

import PopupService from '@core/popup/utils/PopupService';
import ROUTES from '@core/application/constants/routes';
import PAYMENT_METHODS from '@core/payment/common/constants/paymentMethods';
import generatePayUrl from '@core/payment/common/utils/generatePayUrl';
import PAYMENT_ACTIONS from '@core/payment/common/constants/paymentActions';
import trackPaymentClick from '@core/payment/common/utils/trackPaymentClick';
import {getPageViewTime} from '@core/payment/payProcess/utils/pageViewTime';
import {CARD_FORM_NAME} from '@core/payment/payProcess/constants/formNames';
import getPayMethodWithBackboneModel from '@core/payment/payProcess/utils/getPayMethodWithBackboneModel';
import PAY_BUTTON_TRACKING from '@core/payment/widgets/buttons/constants/payButtonTracking';
import PAYMENT_SOURCES from '@core/payment/common/constants/paymentSources';
import usePaymentPackages from '@core/payment/widgets/package/utils/usePaymentPackages';
import generatePayUrlForCoins from '@core/payment/common/utils/generatePayUrlForCoins';
import getProcessPaymentFlow from '@core/payment/payProcess/utils/getProcessPaymentFlow';
import cachedPurchasedPackage from '@core/payment/common/utils/cachedPurchasedPackage';
import {MICROFEATURES_VIA} from '@core/payment/common/constants/vias';
import isCardOneClickAllowed from '@core/payment/forms/card/utils/isCardOneClickAllowed';
import PAYMENT_ONECLICK_FLOWS from '@core/payment/common/constants/paymentOneclickFlows';
import usePaymentParams from '@core/payment/common/utils/usePaymentParams';
import NotificationsService from '@core/systemNotifications/utils/NotificationsService';
import PaymentPageSuccessOrders from '@core/payment/payProcess/utils/PaymentPageSuccessOrders';

import updateMicroFeaturesData from '@phoenix/payment/payProcess/utils/updateMicroFeaturesData';
import CREDITS_BALANCE_QUERY from '@phoenix/credits/graphql/queries/creditsBalance.gql';
import getMicroFeaturesCoinsPopupFlow from '@phoenix/payment/payProcess/paymentFlow/flows/getMicroFeaturesCoinsPopupFlow';
import useOneClickFlow from '@phoenix/payment/widgets/oneClickFlow/utils/useOneClickFlow';

import openMicrofeaturesSuccessPopup from '../../popups/utils/openMicrofeaturesSuccessPopup';
import {MICROFEATURES} from '../constants/microfeatures';
import MicrofeaturesContext from '../containers/MicrofeaturesContext';
import {MODEL_TYPES} from '../constants/modelTypes';

/**
 * @deprecated Use usePayForMicrofeature.js
 * @param {Object} params
 * @param {string} params.featureName (@see MICROFEATURES from common/constants/microfeatures.js)
 * @return {{
 * pay: {function} oneClick payment or redirect to PP,
 * processingPayment: {boolean} payment state,
 * packagesError: {ApolloError|Undefined}
 * packagesLoading: {boolean}
 * }}
 */
const usePayForMicrofeatureLegacy = ({
  featureName,
  stockId: featureStockId,
} = {}) => {
  // @see MicrofeaturesProvider.js
  const {modelType, getFeatureData} = useContext(MicrofeaturesContext);
  const {pathname} = useLocation();
  const creditModel = modelType === MODEL_TYPES.CREDIT;
  const {data: balanceData} = useQuery(CREDITS_BALANCE_QUERY, {
    skip: !creditModel,
  });

  const {action, via} = usePaymentParams();

  const {activePackage, error, loading} = usePaymentPackages({
    needResetCache: true,
  });

  const {oneClickFlow} = useOneClickFlow();

  const featureData = getFeatureData({featureName});
  const stockId = featureStockId || featureData.stockId;

  const history = useHistory();
  const [processingPayment, setProcessingPayment] = useState(false);

  const trackClick = useCallback(
    ({isButtonActive = true} = {}) => {
      trackPaymentClick({
        action,
        isFormHidden: true,
        isFrontendValidationPassed: true,
        isOneClickShown: false,
        isButtonActive,
        payButton: PAY_BUTTON_TRACKING.DEFAULT,
        paymentForm: CARD_FORM_NAME,
        timeInterval: getPageViewTime(),
      });
    },
    [action],
  );

  const handleSuccessfulPayment = useCallback(async () => {
    setProcessingPayment(false);

    PopupService.closePopup();
    PaymentPageSuccessOrders.clear();
    openMicrofeaturesSuccessPopup({featureName});
    NotificationsService.addNotification({
      type: NotificationsService.TYPES.SUCCESS_PAYMENT,
    });

    /**
     * No need to use updateCachesAfterPayment after microfeature payment,
     * because it currently doesn't change none of that data (search users, account status, viewPermissions and so on)
     */
    await updateMicroFeaturesData();
  }, [featureName]);

  const handleCreditPayment = useCallback(
    async (result) => {
      if (result.status) {
        await handleSuccessfulPayment();
      } else {
        // When we got Decline we redirect to PP and reset Cached package
        const purchasedPackage = cachedPurchasedPackage.get();

        history.push(
          generatePayUrl({
            stage: action,
            urlParams: {
              via: result.failPageLogic,
              prevVia: result.via,
              stockId: purchasedPackage?.stockId,
            },
          }),
        );
      }
    },
    [handleSuccessfulPayment, action, history],
  );

  const makePayment = getPayMethodWithBackboneModel({
    action,
    method: PAYMENT_METHODS.CARD,
    payFromPopup: true,
  });

  const buyMicrofeature = useCallback(async () => {
    if (processingPayment) {
      trackClick({isButtonActive: false});
      return;
    }

    setProcessingPayment(true);
    trackClick();

    const returnPathParams = {
      returnPath: pathname,
    };
    const payUrlParams = {
      prevVia: featureName,
      viaMethod: PAYMENT_METHODS.CARD,
      packageId: stockId,
      ...returnPathParams,
    };

    const isOneClickAllowed = await isCardOneClickAllowed({
      source: PAYMENT_SOURCES.POPUP,
      fetchPolicy: 'network-only',
      via: featureName,
    });

    if (isOneClickAllowed && oneClickFlow !== PAYMENT_ONECLICK_FLOWS.SIMPLE) {
      if (modelType === MODEL_TYPES.CREDIT) {
        history.push(generatePayUrlForCoins({via: featureName}));
      } else {
        history.push(
          generatePayUrl({
            stage: action,
            urlParams: {
              via: MICROFEATURES_VIA,
              ...payUrlParams,
            },
          }),
        );
      }
      return;
    }

    if (modelType === MODEL_TYPES.CREDIT) {
      const {balance} = balanceData.credits;
      const canExchange = balance > featureData.creditAmount;

      if (!isOneClickAllowed && !canExchange) {
        history.push(generatePayUrlForCoins({via: featureName}));
      }

      if (canExchange) {
        const makeExchange = getPayMethodWithBackboneModel({
          action: PAYMENT_ACTIONS.EXCHANGE_CREDIT,
          method: PAYMENT_METHODS.CARD,
          payFromPopup: true,
        });

        const exchangeResult = await makeExchange({
          stockId: featureData.packageId,
          prevVia: featureName,
          hidePaymentForm: 1,
          isInstantRepeat: 0,
        });

        await handleCreditPayment(exchangeResult);

        return;
      }

      const processPaymentFlow = getProcessPaymentFlow(
        getMicroFeaturesCoinsPopupFlow(),
      );

      const paymentAnswer = await processPaymentFlow({
        form: {
          hidePaymentForm: 1,
        },
        activePackage,
        action,
        via,
      });

      await handleCreditPayment(paymentAnswer);

      return;
    }

    if (isOneClickAllowed) {
      const result = await makePayment({
        stockId,
        prevVia: featureName,
        hidePaymentForm: 1,
        isInstantRepeat: 0,
      });

      if (result.status) {
        await handleSuccessfulPayment();
      } else {
        const paymentVia = result.failPageLogic
          ? result.failPageLogic
          : MICROFEATURES_VIA;

        if (featureName === MICROFEATURES.TELEGRAM_CONVERSATION) {
          returnPathParams.returnPath = ROUTES.SEARCH;
          returnPathParams.skipStepOnBack = true;
        }

        history.push(
          generatePayUrl({
            stage: action,
            urlParams: {
              via: paymentVia,
              ...payUrlParams,
              ...returnPathParams,
            },
          }),
        );
      }

      return;
    }

    // go to PP if oneClick is not allowed
    history.push(
      generatePayUrl({
        stage: action,
        urlParams: {
          via: MICROFEATURES_VIA,
          ...payUrlParams,
        },
      }),
    );
  }, [
    action,
    via,
    activePackage,
    balanceData,
    featureData.creditAmount,
    featureData.packageId,
    featureName,
    handleCreditPayment,
    handleSuccessfulPayment,
    history,
    makePayment,
    modelType,
    pathname,
    processingPayment,
    stockId,
    trackClick,
    oneClickFlow,
  ]);

  return {
    packagesError: error,
    packagesLoading: loading,
    processingPayment,
    pay: buyMicrofeature,
  };
};

export default usePayForMicrofeatureLegacy;
