import React from 'react';
import type {FC} from 'react';
import {useQuery} from '@apollo/client';

import usePaymentParams from '@core/payment/common/utils/usePaymentParams';
import type {Action} from '@core/types';

import ErrorBoundary from '@phoenix/graphql/components/ErrorBoundary';
import {PlaceholderBar} from '@phoenix/ui';
import {TextMuted} from '@phoenix/typography';
import {Align} from '@phoenix/ui/constants';

import FooterRow from '../components/FooterRow';
import GUARANTEES_AND_RETURN_STATEMENT_QUERY from '../graphql/queries/guaranteesAndReturnStatement.gql';
import type {
  GuaranteesAndReturnStatementQuery,
  GuaranteesAndReturnStatementQueryVariables,
} from '../graphql/queries/guaranteesAndReturnStatement';

type PaymentFooterGuaranteesAndReturnsStatementProps = {
  /**
   * This stub is only for backbone PP, it will be removed with backbone PP
   * usePaymentParams::action doesn't work correctly for alt methods
   */
  stage?: Action;
};

const PaymentFooterGuaranteesAndReturnsStatement: FC<
  PaymentFooterGuaranteesAndReturnsStatementProps
> = ({stage}) => {
  const {action} = usePaymentParams();
  const {data, loading, error} = useQuery<
    GuaranteesAndReturnStatementQuery,
    GuaranteesAndReturnStatementQueryVariables
  >(GUARANTEES_AND_RETURN_STATEMENT_QUERY, {
    variables: {
      action: stage || action,
    },
  });

  if (error) {
    return (
      <FooterRow>
        <ErrorBoundary />
      </FooterRow>
    );
  }

  const guaranteesAndReturnsStatement =
    data?.payment?.paymentFooter?.blockDataMap?.guaranteesAndReturnsStatement;

  if (!loading && !guaranteesAndReturnsStatement) {
    return null;
  }

  return (
    <FooterRow align={Align.CENTER}>
      {loading ? (
        <PlaceholderBar />
      ) : (
        <TextMuted data-test="guarantees" small>
          {guaranteesAndReturnsStatement}
        </TextMuted>
      )}
    </FooterRow>
  );
};

export default PaymentFooterGuaranteesAndReturnsStatement;
