import {useReactiveVar} from '@apollo/client';

import activeMethodTabVar from '@core/graphql/vars/activeMethodTabVar';
import isCardPaymentType from '@core/payment/forms/card/utils/isCardPaymentType';
import usePaymentQuery from '@core/payment/common/utils/usePaymentQuery';
import PaymentOneClickFlows from '@core/payment/common/constants/paymentOneclickFlows';
import ONE_CLICK_PARAMS_QUERY from '@core/payment/forms/card/graphql/queries/oneClickParams.gql';
import type {
  OneClickParamsQuery,
  OneClickParamsQueryVariables,
} from '@core/payment/forms/card/graphql/queries/oneClickParams';

import ONE_CLICK_FLOW_DATA_QUERY from '../graphql/queries/paymentOneClickFlowData.gql';
import type {
  PaymentOneClickFlowDataQuery,
  PaymentOneClickFlowDataQueryVariables,
} from '../graphql/queries/paymentOneClickFlowData';

type OneClickFlowData = {
  loading: boolean;
  oneClickFlow: PaymentOneClickFlows | string;
  withCustomOneClickFlow: boolean;
  oneClickFlowWith3ds: string;
  oneClickFlowWithCvv: string;
};

const useOneClickFlow = (): OneClickFlowData => {
  const activeMethod = useReactiveVar(activeMethodTabVar);

  const isCardMethod = isCardPaymentType(activeMethod);

  const {data: oneClickParamsData, loading: oneClickParamsLoading} =
    usePaymentQuery<OneClickParamsQuery, OneClickParamsQueryVariables>(
      ONE_CLICK_PARAMS_QUERY,
      null,
      {
        reloadOnDecline: true,
      },
    );

  const {data: oneClickFlowData, loading: oneClickFlowLoading} =
    usePaymentQuery<
      PaymentOneClickFlowDataQuery,
      PaymentOneClickFlowDataQueryVariables
    >(ONE_CLICK_FLOW_DATA_QUERY);

  const {oneClickFlowWith3ds = '', oneClickFlowWithCvv = ''} =
    oneClickFlowData?.payment.paymentFooter.blockDataMap || {};

  const isAllowedOneClick =
    isCardMethod && oneClickParamsData?.payment.isOneClickAllowed;

  const oneClickFlow = isAllowedOneClick
    ? oneClickParamsData?.payment.card?.oneClickFlow ||
      PaymentOneClickFlows.SIMPLE
    : null;

  return {
    loading: oneClickParamsLoading || oneClickFlowLoading,
    oneClickFlow,
    withCustomOneClickFlow:
      Boolean(oneClickFlow) && oneClickFlow !== PaymentOneClickFlows.SIMPLE,
    oneClickFlowWith3ds,
    oneClickFlowWithCvv,
  };
};

export default useOneClickFlow;
