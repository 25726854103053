import type {FC} from 'react';
import React from 'react';

import t from '@core/translations/translate';
import type {HotlineCreditCard} from '@core/types';

import {Link, TextMuted, Text} from '@phoenix/typography';

import FooterRow from './FooterRow';

// export for tests
export type PaymentHotLineProps = HotlineCreditCard & {
  inverse?: boolean;
  shortInfo?: boolean;
  small?: boolean;
};

/**
 * Render company address and phone number
 */
const PaymentHotLine: FC<PaymentHotLineProps> = ({
  inverse = false,
  shortInfo = false,
  phoneNumber,
  workingHoursFrom,
  workingHoursTo,
  translateMessageEntityMap,
  small,
}) => {
  if (shortInfo) {
    if (phoneNumber) {
      return (
        <FooterRow>
          <TextMuted inverse={inverse} data-test="helpLine" small={small}>
            {`${t('paymentPageSidebar', 'text.free_helpline')} `}
            <Text inline bold inverse={inverse} small={small}>
              {phoneNumber}
            </Text>
          </TextMuted>
        </FooterRow>
      );
    }

    return null;
  }

  const {hotlineCreditCard, openningWorkTime, workTimeHour} =
    translateMessageEntityMap;

  const workingTime =
    workingHoursFrom &&
    workTimeHour
      .replace('{from}', workingHoursFrom)
      .replace('{to}', workingHoursTo);

  if (!hotlineCreditCard && !phoneNumber && !workingTime) {
    return null;
  }

  return (
    <FooterRow>
      {hotlineCreditCard && `${hotlineCreditCard} `}
      {phoneNumber && (
        <Link
          data-test="phoneNumber"
          href={`tel:${phoneNumber}`}
          small
          inherit
          underline
        >
          {phoneNumber}
        </Link>
      )}
      {workingTime && (
        <TextMuted
          data-test="workingTime"
          small
          textDecoration={Text.DECORATION.UNDERLINE}
        >
          <div>{openningWorkTime}</div>
          {workingTime}
        </TextMuted>
      )}
    </FooterRow>
  );
};

export default PaymentHotLine;
