import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import type {TribeAttribute, TribeDictionary} from '@core/types/graphql';

import Tags from '../constants/tags';

type Profile = {
  attributes?: {
    tribe?: TribeAttribute[];
  };
  statuses?: {
    isVirtualAssistant?: boolean;
    isExHumanAssistant?: boolean;
    isNew?: boolean;
    isDiscreetProfile?: boolean;
    isWebcamProfile?: boolean;
    isModerator?: boolean;
  };
  photos?: {
    hasPrivatePhotos?: boolean;
  };
};

export type User = {
  profile?: Profile;
  blockUser?: {
    iAm?: boolean;
  };
  reportUser?: {
    iAm?: boolean;
  };
  crossVisionData?: {
    partnerNetwork?: boolean;
  };
};

const getTribesTags = (
  dictionaryTribes: TribeDictionary[] | null,
  tribes: TribeAttribute[],
  loadingDictionary: boolean,
): string[] => {
  if (isEmpty(tribes) || loadingDictionary) return [];

  if (!dictionaryTribes) {
    // eslint-disable-next-line no-console
    console.error(
      '[getTagsFromUser] There is no tribes dictionary data of current user in cache',
    );
    return [];
  }

  return tribes.map((key) => find(dictionaryTribes, {key}).translation);
};

type GetTagsFromUserParams = {
  user: User;
  dictionaryTribes?: TribeDictionary[];
  excludePartnerNetwork?: boolean;
  maxCount?: number | null;
  onlyFirstTribe?: boolean;
  loadingDictionary?: boolean;
};

/**
 * Extract from pure 'TargetUserProfile' type that arrives
 * directly from API all data, related to tags.
 */
const getTagsFromUser = ({
  user,
  dictionaryTribes = null,
  excludePartnerNetwork = false,
  maxCount = null,
  onlyFirstTribe = false,
  loadingDictionary = false,
}: GetTagsFromUserParams): string[] => {
  const tags: string[] = [];

  // no tags on blocked or reported users
  if (!user || user.blockUser?.iAm || user.reportUser?.iAm) {
    return tags;
  }

  const {profile = {}, crossVisionData: {partnerNetwork} = {}} = user;
  const {statuses} = profile;

  if (statuses?.isVirtualAssistant || statuses?.isExHumanAssistant) {
    return [Tags.VIRTUAL_PARTNER];
  }

  if (partnerNetwork && !excludePartnerNetwork) {
    tags.push(Tags.PARTNER_NETWORK);
  }

  const tribeAttributes = get(profile, 'attributes.tribe');
  const tribes = tribeAttributes
    ? getTribesTags(
        dictionaryTribes,
        tribeAttributes.slice(0, onlyFirstTribe ? 1 : tribeAttributes.length),
        loadingDictionary,
      )
    : null;

  if (!isEmpty(tribes)) {
    tags.push(...tribes);
  }

  if (statuses?.isNew) {
    tags.push(Tags.NEW);
  }

  if (statuses?.isDiscreetProfile) {
    tags.push(Tags.DISCREET_PROFILE);
  } else if (profile?.photos?.hasPrivatePhotos) {
    tags.push(Tags.PRIVATE_PHOTOS);
  }

  if (statuses?.isWebcamProfile) {
    tags.push(Tags.LIVECAM);
  }

  if (statuses?.isModerator) {
    tags.push(Tags.MODERATOR);
  }

  if (maxCount && tags.length > maxCount) {
    tags.splice(maxCount);
  }

  return tags;
};

export default getTagsFromUser;
