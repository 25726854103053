import {useReactiveVar} from '@apollo/client';

import PAYMENT_METHODS from '@core/payment/common/constants/paymentMethods';
import usePaymentQuery from '@core/payment/common/utils/usePaymentQuery';
import usePaypalSettings from '@core/payment/widgets/paypal/utils/usePaypalSettings';
import PAYMENT_BUTTON_TITLE_QUERY from '@core/payment/widgets/buttons/graphql/queries/paymentButtonTitle.gql';
import usePaymentParams from '@core/payment/common/utils/usePaymentParams';
import useIsMobFirstStage from '@core/payment/common/utils/useIsMobFirstStage';
import getPayButtonTranslate from '@core/payment/widgets/buttons/utils/getPayButtonTranslate';
import {PAY_APP_FUNNEL_VIAS} from '@core/payment/common/constants/vias';
import activeMethodTabVar from '@core/graphql/vars/activeMethodTabVar';

import useIsMobOneStep from '../../../common/utils/useIsMobOneStep';
import getActivePackageFullPriceWithCurrency from '../../../common/utils/getActivePackageFullPriceWithCurrency';

/**
 * Get pay button title for PayPal with one click.
 * @param {Array} paymentMethodButtonTitle
 * @return {String}
 */
const getPayPalOneClickButtonTitle = (paymentMethodButtonTitle = []) => {
  const paypalMethod = paymentMethodButtonTitle.find(
    ({paymentMethod}) => paymentMethod === PAYMENT_METHODS.PAY_PAL_V2,
  );

  return getPayButtonTranslate(paypalMethod?.oneClickButtonText);
};

/**
 * Get pay button title for correct step.
 * @param {Boolean} isMobFirstStage
 * @param {Boolean} isOneStep
 * @param {Object} method
 * @return {String}
 */
const getButtonTitleBySteps = (isMobFirstStage, isOneStep, method) => {
  if (isMobFirstStage && !isOneStep) {
    return getPayButtonTranslate(method.nextStep);
  }

  return getPayButtonTranslate(method.pay);
};

/**
 * Used for getting title text for button for redirect to payment card form and pay button
 * @param {Object} props
 * @param {Array} props.walletMethods - List of wallet methods
 * @param {Boolean} [props.skip]
 * @return {String}
 */
const usePaymentButtonTitle = ({walletMethods = [], skip = false} = {}) => {
  const {via} = usePaymentParams();
  const activeMethod = useReactiveVar(activeMethodTabVar);
  const isMobFirstStage = useIsMobFirstStage();
  const {isOneStep, loading} = useIsMobOneStep();
  const {isOneClickUsed: isPaypalOneClickUsed, loading: paypalLoading} =
    usePaypalSettings();

  const {
    data,
    loading: buttonTitleLoading,
    error,
  } = usePaymentQuery(
    PAYMENT_BUTTON_TITLE_QUERY,
    {
      skip,
    },
    {changeViaOnDecline: true},
  );

  if (loading || paypalLoading || buttonTitleLoading || error || skip) {
    return '';
  }

  const options = {
    '{price}': getActivePackageFullPriceWithCurrency(),
  };

  const {
    buttonTitle: {nextStep, pay},
    paymentMethodButtonTitle,
  } = data.payment.templateSettings;

  const activePaymentMethod = paymentMethodButtonTitle.find(
    ({paymentMethod}) => paymentMethod === activeMethod,
  );

  if (activePaymentMethod) {
    return getButtonTitleBySteps(
      isMobFirstStage,
      isOneStep,
      activePaymentMethod,
    );
  }

  if (walletMethods?.length) {
    if (PAY_APP_FUNNEL_VIAS.FT_CARD_TRIAL === via) {
      return getPayButtonTranslate('button.pay_price_with_card', options);
    }

    if (PAY_APP_FUNNEL_VIAS.FT_CARD_PP === via) {
      return getPayButtonTranslate('button.get_special_offer_with_card');
    }

    if (isPaypalOneClickUsed) {
      return getPayPalOneClickButtonTitle(paymentMethodButtonTitle);
    }

    const walletMethod = paymentMethodButtonTitle.find(({paymentMethod}) =>
      walletMethods.includes(paymentMethod),
    );

    if (walletMethod) {
      return getButtonTitleBySteps(isMobFirstStage, isOneStep, walletMethod);
    }
  }

  const payButtonTranslate = isPaypalOneClickUsed
    ? getPayPalOneClickButtonTitle(paymentMethodButtonTitle)
    : getPayButtonTranslate(pay, options);

  if (isOneStep) {
    return payButtonTranslate;
  }

  /**
   * For web and mob need used different text for button.
   * On mob used for: 1) button go to pay form; 2) pay button;
   * @type {boolean}
   */
  if (isMobFirstStage) {
    return getPayButtonTranslate(nextStep, options);
  }

  return payButtonTranslate;
};

export default usePaymentButtonTitle;
