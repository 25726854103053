import type {ApolloError} from '@apollo/client';
import {useReactiveVar} from '@apollo/client';

import paymentCredentialsPolicyCheckedVar from '@core/graphql/vars/paymentCredentialsPolicyCheckedVar';
import usePaymentQuery from '@core/payment/common/utils/usePaymentQuery';
import type {PaymentMethods} from '@core/payment/common/types/paymentMethods';
import type {
  PaymentCredentialsPolicyQuery,
  PaymentCredentialsPolicyQueryVariables,
} from '@core/payment/widgets/billingPolicy/graphql/queries/paymentCredentialsPolicy';
import PAYMENT_CREDENTIALS_POLICY_QUERY from '@core/payment/widgets/billingPolicy/graphql/queries/paymentCredentialsPolicy.gql';
import activeMethodTabVar from '@core/graphql/vars/activeMethodTabVar';
import isSepaPaymentType from '@core/payment/forms/card/utils/isSepaPaymentType';

import useWithPolicyAgreement from '@phoenix/payment/pages/utils/useWithPolicyAgreement';

type UseSepaCredentialsPolicyReturn = {
  loading: boolean;
  error: ApolloError | null;
  isCredentialsPolicyNeeded: boolean;
  credentialsPolicyDefaultValue: boolean;
  isCredentialsPolicyChecked: boolean;
  setIsCredentialsPolicyChecked: (value: boolean) => void;
};

const useSepaCredentialsPolicy = (): UseSepaCredentialsPolicyReturn => {
  const withPolicyAgreement = useWithPolicyAgreement();

  const isSepaMethod = isSepaPaymentType(
    useReactiveVar(activeMethodTabVar) as PaymentMethods,
  );

  const {data, loading, error} = usePaymentQuery<
    PaymentCredentialsPolicyQuery,
    PaymentCredentialsPolicyQueryVariables
  >(PAYMENT_CREDENTIALS_POLICY_QUERY);

  const isCredentialsPolicyChecked = useReactiveVar(
    paymentCredentialsPolicyCheckedVar,
  );

  return {
    loading,
    error,
    isCredentialsPolicyNeeded:
      Boolean(
        data?.payment.paymentFooter.blockDataMap.textSepaBillingPolicy
          .isShowCredentialsPolicy,
      ) &&
      isSepaMethod &&
      withPolicyAgreement,
    credentialsPolicyDefaultValue:
      data?.payment.templateSettings.additionalTermsStatusMap.credentialsPolicy,
    isCredentialsPolicyChecked,
    setIsCredentialsPolicyChecked: paymentCredentialsPolicyCheckedVar,
  };
};

export default useSepaCredentialsPolicy;
